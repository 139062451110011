.page-header {
    display: flex;
}
.input-gr {
    padding-right: 5px;
}
.input-gl {
    padding-left: 5px;
}

.profile-settings-section {
    padding: 35px;
}

.card-layout-calendar {
    color: white;
    background-color: rgba(37, 45, 71, 1);
    border-radius: 20px;
}

.profile-image figure {
    margin: 0 auto 10px;
    max-width: 150px;
}

.profile-image figure img {
    border-radius: 100%;
    height: 150px;
    width: 150px;
    object-fit: cover;
}

.profile-img-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.form-control:disabled {
    background: #515b7d;
    border: #515b7d;
    color: #d2e5ff;
    opacity: 0.5;
}
.change-btn,
.submit-btn,
.submit-btn:hover,
.change-btn:hover {
    background: #8b0032;
    color: white;
    border: 1px solid #8b0032;
}
.submit-btn:disabled {
    background: lightslategrey;
}

.change-btn:active,
.submit-btn:active {
    background: #8b0032 !important;
    color: white !important;
    border: 1px solid #8b0032 !important;
}

.delete-btn,
.delete-btn:hover {
    background: #252d47;
    color: white;
    border: 1px solid white;
}

.profile-setting-form .form-label {
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
}
.profile-setting-form input,
.profile-setting-form input:focus,
.profile-setting-form textarea,
.profile-setting-form textarea:focus {
    background: #515b7d;
    border: #515b7d;
    color: #d2e5ff;
    padding: 12px;
    height: auto;
}

.profile-image-container {
    display: flex;
    gap: 35px;
}

.form-textarea {
    overflow-y: scroll;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    width: 100%;
}

.form-textarea::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.form-textarea::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.form-textarea::-webkit-scrollbar-thumb {
    background: rgba(172, 185, 202, 1);
    border-radius: 10px;
}

/* Handle on hover */
.form-textarea::-webkit-scrollbar-thumb:hover {
    background: rgba(172, 185, 202, 1);
}

.profile-image-loader {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    border-radius: 50%;
    height: 100%;
    display: flex;
    opacity: 0.5;
    align-items: center;
    justify-content: center;
    background-color: rgb(3 3 3);
}

.skills {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.skills__badge {
    border-radius: 0.25rem;
    --tw-bg-opacity: 1;
    background-color: rgb(30 66 159/var(--tw-bg-opacity));
    padding: 0.125rem 0.625rem;
    font-size: .875rem;
    line-height: 1.25rem;
    font-weight: 500;
}

/* ===== media queries for mobile devices ===== */
@media (max-width: 374px) {
    .profile-image-container {
        gap: 10px;
        flex-wrap: wrap;
        justify-content: center;
    }
    .profile-img-btn {
        width: 100%;
    }
    .profile-settings-section {
        padding: 15px !important;
    }
    .profile-image-container {
        gap: 24px;
    }
}

@media (max-width: 575px) {
    .Toastify__toast-container {
        width: 250px !important;
        right: 0px !important;
        left: auto !important;
        padding: 10px !important;
        font-size: 12px !important;
    }
    .input-gr {
        padding-right: 0px;
    }
    .input-gl {
        padding-left: 0px;
    }
    .profile-image-container {
        gap: 24px;
    }
}
