.main-wrapper {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.page-wrapper {
    padding-top: 70px;
    margin-left: 270px;
    min-height: 100vh;
    background-color: rgba(28, 34, 55, 1);
    color: white;
}

.header {
    height: 65px;
    background-color: rgba(37, 45, 71, 1);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    z-index: 1011;
}

.mobile-header-bg {
    background-color: rgba(37, 45, 71, 1);
}

.left-sidebar-desktop {
    padding-top: 80px;
}

.offcanvas {
    transform: none;
    background: rgba(37, 45, 71, 1);
}

.asidebar-top {
    top: 200px;
}

.navbar-toggler-custom {
    padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
    font-size: var(--bs-navbar-toggler-font-size);
    line-height: 1;
    color: var(--bs-navbar-color);
    background-color: transparent;
    border: none;
    border-radius: var(--bs-navbar-toggler-border-radius);
    transition: var(--bs-navbar-toggler-transition);
}

.header-logo {
    cursor: pointer;
    max-width: 200px;
    max-height: 70px;
    padding-top: 10px;
}

.header-icon {
    cursor: pointer;
}

.left-sidebar {
    background-color: rgba(37, 45, 71, 1);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    color: rgba(219, 219, 255, 1);
    position: fixed;
    top: 0;
    bottom: 0;
    overflow-y: scroll;
}

.left-sidebar .left-navbar {
    margin-top: 65px;
    font-weight: 600;
    font-size: 20px;
}

.left-sidebar::-webkit-scrollbar {
    display: none;
}

.offcanvas {
    width: 250px !important;
}

.offcanvas-header {
    z-index: 9;
    justify-content: end;
    background-color: rgba(37, 45, 71, 1);
}

.left-sidebar-desktop {
    width: 285px;
}

.left-sidebar-mobile {
    width: 260px;
}

.left-sidebar ul {
    padding: 0;
}

.left-sidebar ul li {
    padding-left: 0;
    list-style-type: none;
    font-size: 14px;
    line-height: 24.2px;
    cursor: pointer;
}

.leftSidebar-active {
    border-radius: 32px 0 0 32px;
    background-color: rgba(139, 0, 50, 1);
    color: white;
}

.icon-bg {
    color: rgba(219, 219, 255, 1);
}

.card-layout-calendar {
    color: white;
    background-color: rgba(37, 45, 71, 1);
    border-radius: 20px;
}

.card-layout {
    background-color: rgba(37, 45, 71, 1);
    border-radius: 20px;
    padding: 20px;
}

.icon-active {
    color: rgba(255, 224, 224, 1);
}
.header-section .header-profile-image {
    height: 30px;
    width: 30px;
    border-radius: 100%;
    object-fit: cover;
}

.siderbar-middle-item {
    margin-top: 10rem;
    margin-bottom: 10rem;
}

.siderbar-middle-item-top {
    margin-top: 10rem;
}

.submenu {
    border-left: 5px solid #a07e59;
    margin: 15px 0px 15px 40px;
}

.submenu li {
    padding: 0 20px 25px 20px !important;
}

.submenu li:last-child {
    padding-bottom: 0 !important;
}

@media (max-width: 991px) {
    .page-wrapper {
        margin-left: 0;
    }
    .left-sidebar ul li {
        font-size: 12px;
    }
}
